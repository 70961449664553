.forma {
  margin-top: 0rem;
  margin: 1rem 0;
  align-items: center;
  justify-content: center;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0.75rem;
}
/* ImageUpload.module.css */
.redParagraph {
  color: rgb(231, 235, 20);
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
  color: white;
}

.control input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}
.control select option {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.cancelButten {
  font: inherit;
  color: #fffefe;
  cursor: pointer;
  background-color: transparent;
  border-color: #5a1a01;
  border-radius: 25px;
  padding: 0.5rem 2rem;
  font-weight: bold;
}

.submitbutton {
  font: inherit;
  color: #fffcfb;
  cursor: pointer;
  background-color: #5a1a01;
  border-color: white;
  border-radius: 25px;
  padding: 0.5rem 2rem;
  font-weight: bold;
}

.actions .cancelButten:hover,
.actions .cancelButten:active {
  border-color: solid #5a1a01;
  background-color: #f8e220;
  color: white;
  font-weight: bold;
}

.actions .submitbutton:hover,
.actions .submitbutton:active {
  border-color: 1px solid #5a1a01;
  background-color: #f8e220;
  color: white;
  font-weight: bold;
}

.invalid label {
  color: #ca3e3e;
}

.invalid input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}
.birthdayControlClasses {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80rem;
  max-width: 100%;
  height: 80rem;
}
.memberNumber {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
}
.imageContainera {
  width: 85mm; /* ID card width */
  height: 54mm; /* ID card height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure the image does not overflow the container */
  padding: 10px; /* Add padding inside the container */
  box-sizing: border-box; /* Ensure padding does not affect the total width and height */
}

.imagea {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container without stretching */
}
