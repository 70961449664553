.fullscreenImage {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.homeContainer {
  display: block;
  /* height: 100; */
  position: relative;
}

.textContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2rem;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  gap: 1rem;
  text-transform: uppercase;
}

.text {
  font-size: 3rem;
  background-color: transparent;
  border: 3px solid rgb(211, 226, 9);
  color: whitesmoke;
  width: fit-content;
  border-radius: 40px;
  font-weight: bold;
  padding: 0rem 1rem;
}

.textSite {
  color: var(--clr-primary-1);
  background-color: var(--clr-primary-7);
  padding: 0rem 1rem;
}


@media (max-width: 400px) {
  .text {
  font-size: 1rem;
  background-color: transparent;
  border: 3px solid rgb(211, 226, 9);
  color: whitesmoke;
  width: fit-content;
  border-radius: 40px;
  font-weight: bold;
  padding: 0rem 1rem;
}
  .textContainer {

  height: auto;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 0rem;
  top: 0rem;
}
}