.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
}
.firstRows {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  gap: 1rem;
  padding: 0.3rem;
}
.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: 3px solid blue;
  color: rgb(189, 26, 26);
}
.imageContainer {
  align-self: flex-start;
}
.address {
  order: 2;
  text-align: left;
}
.addressText {
  color: blue;
}
.addressTextdetails {
  color: rgb(0, 0, 2);
  font-size: 0.8rem;
}
.secondRow {
  display: flex;
  color: rgb(0, 0, 2);
  gap: 0.3rem;
  padding: 0.2rem 0;
}
.thirdRow {
  display: flex;
  justify-content: flex-start;
  color: rgb(0, 0, 2);
  gap: 0.3rem;
  /* padding: 0.2rem ; */
}
.fourthRow {
  display: flex;
  justify-content: flex-start;
  color: rgb(0, 0, 2);
  gap: 0.3rem;
}
.image {
  width: 5rem;
  height: 5rem;
}
.receiptNumber {
  order: 3;
  text-align: right;
  margin-left: auto;
}
.receivedFromLabel {
  font-size: 1.2rem;
}
.provinceControlClasses {
  font-size: 1rem;
}
.input {
  font-size: 1rem;
  border-radius: 0;
  flex-grow: 1;
  margin: 0.2rem;
}
.inputAmount {
  font-size: 1rem;
  border-radius: 0;
  flex-grow: 1;
  margin: 0.2rem;
  width: 5rem;
}
.amount {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  margin-right: 0.2rem;
}
.date {
  margin-right: 0.2rem;
}
.imageCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imageinput {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-color: var(--clr-gold);
  font-size: 1rem;
  color: black;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: 0.25rem;
}

.imageSava {
  font: inherit;
  color: #0c39cd;
  cursor: pointer;
  background-color: #f3e410;
  border-color: white;
  border-radius: 25px;
  padding: 0rem 0.5rem;
  font-weight: bold;
}
.profile-photo {
  width: 100%;
}

.profile-photo img {
  border-radius: 2%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
