.custom-select {
  position: relative;
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.custom-select select {
  appearance: none;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 5px solid #333;
}
