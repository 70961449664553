/* Center the dropdown inside the container */
.dropdownContainer {
  display: flex;
  justify-content: center;
  margin: 10px 0; /* Adjust margins as needed */
}

/* Style the dropdown */
.filterDropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
