.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.even-row {
  background-color: #e6f7ff;
}

.odd-row {
  background-color: #f2e6ff;
}
