/* ImageDetails.module.css */

.ImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

PayButton {
  margin-bottom: 10px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}
button {
  margin-bottom: 10px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  margin: 1rem;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.image {
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
  height: auto; /* Maintain the aspect ratio of the image */
}

/* Customize the following styles for different sizes */


.cardSize {
  max-width: 40rem;
  max-height: auto;
}
