displayMember {
  display: flex;
  justify-content: space-between;
}
.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--clr-background-color);
  padding: 0.8rem 1rem;
  border-bottom: 2px solid var(--clr-primary-7);
  margin-bottom: 2rem;
  gap: 0.1rem;
}
.button_ud {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.expense-item__description {
  display: flex;
  flex-direction: column; /* Change from column to row */
  flex-flow: column-reverse;
  justify-content: space-around;
  flex: 1;
  color: white;
}

.discription {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  margin: 0 1rem;
}
.expense-item h2 {
  color: #3a3a3a;
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  color: white;
}

.expense-item__absent {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #972323;
  border: 1px solid rgb(255, 255, 255);
  padding: 0.5rem;
}
.expense-item__pressent:hover,
.expense-item__pressent:active {
  background-color: #e9e63e;
}
.expense-item__absent:hover,
.expense-item__absent:active {
  background-color: #e2e62a;
}
.expense-item__pressent {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #0d4605;
  border: 1px solid white;
  padding: 0.5rem 1.1rem;
}
@media (min-width: 580px) {
  .expense-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex: 1;
  }

  .expense-item__description h2 {
    font-size: 1.25rem;
  }

  .expense-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}
