.forma {
    margin-top: 0rem;
    margin: 1rem 0;
    align-items: center;
    justify-content: center;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0.375rem 0.75rem;
  }
  /* ImageUpload.module.css */
  .redParagraph {
    color: rgb(231, 235, 20);
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: block;
    color: #f9f5f5;
  }
  
  .control input {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 20rem;
    max-width: 100%;
  }
  .control select option {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 20rem;
    max-width: 100%;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  .cancelButten {
    font: inherit;
    color: #fffefe;
    cursor: pointer;
    background-color: transparent;
    border-color: #5a1a01;
    border-radius: 25px;
    padding: 0.5rem 2rem;
    font-weight: bold;
  }
  
  .submitbutton {
    font: inherit;
    color: #fffcfb;
    cursor: pointer;
    background-color: #5a1a01;
    border-color: white;
    border-radius: 25px;
    padding: 0.5rem 2rem;
    font-weight: bold;
  }
  
  .actions .cancelButten:hover,
  .actions .cancelButten:active {
    border-color: solid #5a1a01;
    background-color: #f8e220;
    color: white;
    font-weight: bold;
  }
  
  .actions .submitbutton:hover,
  .actions .submitbutton:active {
    border-color: 1px solid #5a1a01;
    background-color: #f8e220;
    color: white;
    font-weight: bold;
  }
  
  .invalid label {
    color: #ca3e3e;
  }
  
  .invalid input {
    border-color: #aa0b20;
    background-color: #ffeff1;
  }
  