.b-order {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.b-order:hover {
  background-color: #45a049;
}

.b-order:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.input-order {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.label-order {
  display: block;
  margin-bottom: 5px;
}

.order-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

.reference-number {
  margin-top: 20px;
  padding: 10px;
  border: 2px solid #4caf50;
  background-color: #f9fff9;
  border-radius: 5px;
  font-family: "Arial", sans-serif;
  color: #4caf50;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.reference-number p {
  margin: 0;
}
.msg {
  text-align: center;
  color: #eb1111;
  margin-bottom: 20px;
}
