.title {
  text-align: center;
}
.title_h2 {
  color: yellow;
  margin-bottom: 0.5rem;
}

.title-underline {
  text-align: center;
  width: 5rem;
  height: 0.5rem;
  background: red;
  margin-left: auto;
  margin-right: auto;
}

.branch_h2 {
  top: -0.5rem;
  text-align: center;
  color: aqua;
  margin-left: auto;
  margin-right: auto;
}
