/* Global styles */
.paymentsList {
  margin-top: 20px;
}

/* Styles for headings */
.paymentsList h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white; /* Set color to white */
}

/* Styles for list items */
.paymentsList li {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0 10px 10px 0;
  width: calc(33.33% - 20px); /* Three payments in a row with some spacing */
  box-sizing: border-box;
}

/* Styles for paragraphs */
.paymentsList p {
  margin: 5px 0;
  color: white; /* Set color to white */
}

/* Styles for images */
.paymentsList img {
  max-width: 10rem;
  max-height: 10rem;
  width: auto;
  height: auto;
  object-fit: cover;
}

/* Additional styles for list */
.paymentsList ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

/* Specific styles for table cells */
.tbody-style {
  color: white;
}

.tbody-style td {
  padding: 10px; /* Adjust as needed */
}

/* Styles for links */
.linkCell {
  color: blue;
  text-decoration: double; /* Set text-decoration to 'none' initially */
  font-weight: bold;
}

.linkCell:hover {
  text-decoration: underline; /* Apply underline on hover */
}

.linkCell:visited {
  color: yellow;
  font-weight: bold;
}

/* Styles for specific elements */
.province,
.month {
  color: white; /* Set color to white */
}

.total {
  color: rgb(20, 221, 53);
}
.provinceLink,
.goback {
  text-decoration: none;
  color: #000000; /* Change color as needed */
  background-color: #f0f0f0; /* Change background color as needed */
  padding: 10px 20px;
  border: 2px solid #ccc; /* Change border color and width as needed */
  border-radius: 5px;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
}

.provinceLink:hover,
.goback:hover {
  background-color: #1800f5; /* Change hover background color as needed */
  color: #ffffff; /* Change hover color as needed */
}
