.imageCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .imageinput {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-color: var(--clr-gold);
    font-size: 1rem;
    color: black;
    cursor: pointer;
    transition: var(--transition);
    border-radius: 5px;
    margin-top: auto;
    margin-bottom: 0.25rem;
  }
  
  .imageSave {
    font: inherit;
    color: #fffcfb;
    cursor: pointer;
    background-color: #5a1a01;
    border-color: white;
    border-radius: 25px;
    padding: 0rem 0.5rem;
    font-weight: bold;
  }
  .profile-photo {
    width: 100%;
  }
  
  .profile-photo img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 4px solid var(--clr-grey-8);
    box-shadow: var(--dark-shadow);
  }
  