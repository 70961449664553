.calculatedCard {
  padding: 1rem;
  box-shadow: 0 2px 8px rgb(250, 240, 240);
  background-color: rgba(18, 91, 94, 0.548);
  margin-top: inherit;
  color: white;
  font-weight: bold;
}

/* Calculated.module.css */

.yellowText {
  color: yellow;
  font-weight: bold;
}
.underlineText {
  text-decoration: underline;
}
