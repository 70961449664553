.Members {
  margin-top: auto;
  max-width: 30rem;
  width: 65%;
  margin: 2rem auto;
  animation: meals-appear 1s ease-out forwards;
}

.Members ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes meals-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
