.cart-items {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: scroll;
}

.total {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.actions {
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #8a2b06;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}
.button.confirm {
  border: px solid;
}
.actions button:hover,
.actions button:active {
  background-color: #5a1a01;
  border-color: #5a1a01;
  color: white;
}
/* Style for the "confirm" button */
.actions button.no {
  background-color: green;
  color: white;
  border-color: #050fd0;
  font-size: 20px;
  /* Add other styles as needed */
}

/* Style for the "No" button */
.actions button.confirm {
  font-size: 20px;
  background-color: red;
  color: white;
  border-color: #2d03e8;
  /* Add other styles as needed */
}

/* Hover state for the "confirm" button */
.actions button.confirm:hover {
  background-color: #e2ee0a;
  border-color: #1400c6;
  color: rgb(250, 4, 4);
}

/* Hover state for the "No" button */
.actions button.no:hover {
  background-color: #e2ee0a;
  border-color: #1400c6;
  color: rgb(250, 4, 4);
}

/* Active state for the "confirm" button */
.actions button.confirm:active {
  background-color: #f80606;
  border-color: #120490;
  color: white;
}

/* Active state for the "No" button */
.actions button.no:active {
  background-color: #f80606;
  border-color: #120490;
  color: white;
}
