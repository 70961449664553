/* AccountNumber.module.css */

.container {
    max-width: 600px; /* Adjust the max-width as needed for the size of a back slip */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  .image {
    width: 100%; /* Ensure the image takes up 100% of the container's width */
    height: auto; /* Maintain the aspect ratio of the image */
  }
  