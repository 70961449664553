.loga {
  font: inherit;
  cursor: pointer;

  background-color: var(--clr-primary-9);
  border: 3px solid #000000;
  color: rgb(0, 0, 0);
  width: fit-content;
  border-radius: 25px;
  font-weight: bold;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding: 0.05rem 2rem;
}

.loga:hover,
.loga:active {
  background-color: #ececf0;
  border-color: yellow;
  color: blue;
}
