.member-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #cec763;
  border-radius: 12px;
}
.button_ud {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}
.h2_title {
  color: rgb(187, 33, 13);
}
.h2_member {
  color: white;
}
.h2_member2 {
  color: rgb(235, 219, 7);
}
.button_ud button {
  padding: 20px 20px; /* Adjust padding to increase button size */
  font-size: 1.4rem; /* Increase font size */
  background-color: #0ee231; /* Example background color */
  color: white; /* Text color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button_ud button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.member-item__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  justify-content: flex-start;
  flex: 1;
}
.discription {
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
}
.member-item h2 {
  color: #3a3a3a;
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  color: white;
}

.member-item__absent {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #972323;
  border: 1px solid rgb(172, 23, 23);
  padding: 0.5rem;
  border-radius: 12px;
}
.member-item__pressent:hover,
.member-item__pressent:active {
  background-color: #e9e63e;
}
.member-item__absent:hover,
.member-item__absent:active {
  background-color: #e2e62a;
}
.member-item__pressent {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #0d4605;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
}
@media (min-width: 580px) {
  .member-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .member-item__description h2 {
    font-size: 1.25rem;
  }

  .member-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}
