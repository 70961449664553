.imageCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imageinput {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(241, 231, 231);
  border-color: var(--clr-gold);
  font-size: 1rem;
  color: rgb(26, 12, 227);
  cursor: pointer;
  transition: var(--transition);
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: 0.25rem;
  font-weight: bold;
}
.ptext {
  font: white;
  color: white;
}

.imageSava {
  font: inherit;
  color: #fffcfb;
  cursor: pointer;
  background-color: #f0e514;
  border-color: white;
  border-radius: 25px;
  padding: 0rem 0.5rem;
  font-weight: bold;
}
.profile-photo {
  width: 100%;
}

.profile-photo img {
  border-radius: 2%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
