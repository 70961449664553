/* SearchBar.module.css */
.searchBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.searchInput {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
}

.searchButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3;
}

.resultsContainer {
  margin-top: 20px;
  width: 80%;
}

.resultsTable {
  width: 100%;
  border-collapse: collapse;
}

.tableHeader {
  background-color: #f8f8f8;
}

.tableRow {
  border-bottom: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.tableRow:hover {
  background-color: #f1f1f1;
}

.intableCell {
  padding: 10px;
  text-align: left;
  color: white; /* Make text font white */
  font-weight: bold; /* Make text bold */
}

.noResults {
  font-size: 18px;
  color: #ff0000;
  margin-top: 20px;
}
.tableRow {
  background-color: #333; /* Set background color to dark grey */
}

.tableCell {
  color: black; /* Make text font black */
  font-weight: bold; /* Make text bold */
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #555; /* Add border for better visual separation */
}
.searchButton {
  /* Your default button styles */
}

.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.message {
  margin-top: 5px;
  color: red; /* or any other color you prefer for the message */
}
