.Members {
  text-align: center;

  margin: auto;
  margin-top: 0rem;
  position: relative;

  border-radius: 14px;
  padding: 1rem;
}

.Members h2 {
  font-size: 2rem;
  margin-top: 0;
}
