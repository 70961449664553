.adminBtn {
  font: inherit;
  cursor: pointer;

  margin-top: 1rem;

  background-color: #eceae9;
  border: 3px solid #141413;
  color: rgb(12, 12, 12);
  width: fit-content;
  border-radius: 20px;
  font-weight: bold;
  padding: 0.25rem 1rem; /* Adjust the padding as needed */
}

.adminBtn:hover,
.adminBtn:active {
  background-color: #641e03;
  border-color: #f0ecea;
  color: #faf8f8;
}
