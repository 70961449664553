.idPhoto {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 5.5rem;
  border-color: transparent;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}
.person_m-img {
  margin-top: 3rem;
  margin-left: 0.5rem;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
