.tithe-status-table {
  width: 100%;
  border-collapse: collapse;
}

.tithe-status-table th,
.tithe-status-table td {
  border: 1px solid #ddd;
  padding: 8px;
  color: white; /* Set text color to white */
  font-weight: bold; /* Set text to bold */
}

.tithe-status-table th {
  background-color: #333; /* Dark background for headers */
  text-align: left;
}

.tithe-status-table td {
  background-color: #444; /* Slightly lighter background for cells */
}

.tithe-status-table td.no {
  color: red;
  background-color: #555; /* Slightly lighter background for cells */
}

.tithe-status-table td.yes {
  color: rgb(11, 8, 196);
  background-color: #555; /* Slightly lighter background for cells */
}
