.order-list {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.order-list h2 {
  text-align: center;
  margin-bottom: 15px;
}

.order-list table {
  width: 100%;
  border-collapse: collapse; /* Collapse table borders */
}

.order-list th,
.order-list td {
  border: 1px solid #ddd; /* Light gray border */
  padding: 8px;
  text-align: left;
}

.order-list th {
  background-color: #f2f2f2; /* Light gray background for headers */
  font-weight: bold;
}

.order-list td {
  color: white; /* White text color */
  font-weight: bold; /* Bold text */
}

.order-list .order-item {
  background-color: #4caf50; /* Green background for table rows */
}

.download-button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
